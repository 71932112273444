@import 'assets/styles/variables.scss';

%table-cell-sizes {
  &:nth-child(1) {
    min-width: 155px;
  }
  &:nth-child(2) {
    min-width: 140px;
  }
  &:nth-child(3) {
    min-width: 170px;
  }
  &:nth-child(4) {
    width: 160px;
  }
  &:nth-child(5) {
    width: 110px;
  }
  &:nth-child(6) {
    width: 90px;
  }
  &:nth-child(7) {
    width: 110px;
  }
  &:nth-child(8) {
    width: 50px;
  }
}

.wrapper {
  margin-top: 60px;

  div.table {
    border-radius: 8px;
    margin-bottom: 20px;
    table {
      border-collapse: initial;
    }

    th {
      white-space: nowrap;
      @extend %table-cell-sizes;
    }

    td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      @extend %table-cell-sizes;
      &.error {
        background-color: #f7e5e5 !important;
      }
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
      &:nth-child(4) {
        white-space: normal;
        word-break: break-word;
        cursor: default;
      }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        padding: 0;
      }
      &:last-child {
        padding: 0;
        text-align: center;
        & > button {
          cursor: pointer;
          padding: 5px !important;
          svg {
            width: 20px;
            height: 20px;
            color: black;
          }
        }
      }
    }

    .inputs {
      &__field {
        width: 100%;
        & > div {
          background: transparent;
        }
        input {
          text-align: center;
        }
      }
      &__description {
        background: transparent;
        box-sizing: border-box;
        & > div {
          padding: 12px 14px;
        }
      }
      &__location,
      &__sublocation {
        width: 100%;
        & > div {
          width: 100%;
          & > div {
            width: 100%;
            background-color: transparent;
          }
        }
      }
    }
    .disabled_cell {
      padding: 8px 14px;
      white-space: normal;
      word-break: break-word;
      display: block;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;

      & > span {
        font-style: italic;
        font-weight: 100;
        font-size: 14px;
        margin-bottom: 10px;
        display: block;
        opacity: 0.5;
      }

      &__button {
        min-width: 90px;
        height: 40px;
        border-radius: 8px;
        background-color: #507a8e;
        color: white;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        font-family: $monserrat;
        line-height: 15px;
        display: flex;
        box-shadow: $shadow;
        &:hover {
          background-color: #507a8e;
          box-shadow: $shadow_hover;
        }
        & > span {
          padding-left: 10px;
        }
      }
    }

    .total {
      font-family: $monserrat;
      font-size: 14px;
      font-weight: 700;
      width: 150px;
      margin: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    div.table {
      position: relative;
      left: -20px;
      width: 100vw !important;

      th,
      td {
        &:nth-child(1) {
          min-width: 220px;
        }
        &:nth-child(2) {
          min-width: 200px;
        }
        &:nth-child(3) {
          min-width: 250px;
        }
        &:nth-child(4) {
          min-width: 160px;
        }
        &:nth-child(5) {
          min-width: 110px;
        }
        &:nth-child(6) {
          min-width: 110px;
        }
        &:nth-child(7) {
          min-width: 110px;
        }
        &:nth-child(8) {
          width: 50px;
          min-width: 50px;
        }
      }
    }

    .footer {
      .left {
        width: 100%;
        &__button {
          width: 100%;
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
