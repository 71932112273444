@import 'assets/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  .toggle {
    margin-bottom: 20px;

    button {
      min-width: 125px;
    }

    span {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .controls {
      div.controls__field {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        & > label {
          margin-bottom: 10px;
        }
      }
    }

    div.table {
      position: relative;
      left: -20px;
      width: 100vw;
      td,
      th {
        &:nth-child(1) {
          min-width: 190px;
        }
        &:nth-child(2) {
          min-width: 120px;
        }
      }
    }

    .footer {
      .add_button {
        min-width: 100%;
      }
    }
  }
}

@mixin table {
  .controls {
    margin-bottom: 20px;
    &__field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 427px;

      & > label {
        font-family: $monserrat;
        margin: 0;
        font-size: 14px;
      }
    }

    &__chips {
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 10px;
      margin: 20px 0 0;
      width: 100%;

      &_item {
        background-color: #7aa5ba;
        border-radius: 8px;
        color: white;
        font-family: $monserrat;
        max-width: 100%;

        & > span {
          padding: 6px 16px;
        }

        & > svg {
          color: #fff;
          height: 16px;
        }
      }
    }
  }

  .table {
    border-radius: 8px;
    margin-bottom: 5px;
    table {
      border-collapse: initial;
    }

    th {
      height: 40px;
      white-space: nowrap;
      &:nth-child(1) {
      }
      &:nth-child(2) {
        min-width: 195px;
        width: 195px;
      }
      &:nth-child(3) {
        width: 50px;
        min-width: 50px;
      }
    }

    td {
      box-sizing: border-box;
      -webkit-text-size-adjust: 100%;
      height: 40px;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
      &:nth-child(1) {
        padding: 0;
      }
      &:nth-child(2) {
        min-width: 195px;
        width: 195px;
        padding: 0;
      }
      &:nth-child(3) {
        width: 55px;
        min-width: 55px;
        svg {
          width: 20px;
          height: 20px;
          color: black;
        }
      }
    }

    .redBackground {
      background-color: #ff000012 !important;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 50px;

    .add_button {
      min-width: 90px;
      height: 40px;
      border-radius: 8px;
      background-color: $main_color;
      color: white;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      font-family: $monserrat;
      line-height: 15px;
      display: flex;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0 rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      &:hover {
        background: $main_color;
        box-shadow: 0 2px 4px -1px rgb(0, 0, 0, 0.2), 0px 4px 5px 0 rgba(0, 0, 0, 0.14),
          0 1px 10px 0 rgba(0, 0, 0, 0.12);
      }
    }

    & > span {
      padding-left: 10px;
    }
  }

  .select_input_style {
    & > div {
      & > div {
        background-color: transparent;
      }
    }
  }
  .quantity_input_style {
    width: 100%;
    & > div {
      background-color: transparent !important;
    }
  }
}
