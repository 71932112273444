@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 30px 60px;
  min-height: calc(100vh - 60px - 30px - 30px);
  display: grid;
  grid-template-rows: 81px 66px calc(100% - 81px - 66px);
  grid-template-columns: 100%;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    padding: 60px 20px 20px;
    min-height: calc(100vh - 60px);
    grid-template-rows: 70px 85px calc(100% - 70px - 85px);
  }
}
