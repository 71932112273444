@import 'assets/styles/variables.scss';

.popup {
  width: 1080px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin-bottom: 50px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-self: center;
    column-gap: 20px;
    width: 427px;
    margin-top: auto;
    position: relative;
    & > button {
      width: 46% !important;
      min-width: 46% !important;
      height: 40px;
    }

    & > span {
      position: absolute;
      bottom: -18px;
      left: -14px;
      text-align: end;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: $error_color;
      font-family: Montserrat, sans-serif;
      width: 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popup {
    width: 100%;
    height: auto;
    padding: 70px 20px 20px;

    .footer {
      width: 100%;

      & > span {
        bottom: 50px;
        right: 0;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
}
