@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;

  .pageHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid $main_color;
    margin-bottom: 40px;
    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0 0 10px;
    }
    &__controls {
      display: flex;
      padding-bottom: 10px;

      button {
        margin-left: 30px;
      }

      .downloadButton {
        display: flex;
        align-items: center;
        border: none;
        background: none;
        cursor: pointer;
        font-family: $monserrat;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        outline: none;
        padding: 0;
        img {
          margin-right: 10px;
        }
      }
    }
  }

  .tableContainer {
    margin-top: 10px;
    border-radius: 8px;
    table {
      border-collapse: initial;
    }
    th {
      box-sizing: border-box;
      padding: 4px 16px !important;
      min-width: 120px;
      white-space: nowrap;
    }
    td {
      max-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      -webkit-text-size-adjust: 100%;
      &:first-child {
        min-width: 100px;
        box-sizing: border-box;
      }
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
      &.white {
        background-color: white !important;
      }
      &.header {
        background-color: #e4edf1 !important;
        text-align: center;
      }
      &.total {
        background-color: #7aa5ba !important;
        text-align: center;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
      }
      &.totalTitle {
        font-weight: 700;
        text-transform: uppercase;
      }
      &.totalCount {
        font-weight: 700;
        font-size: 12px;
      }
      .rowName {
        text-decoration: underline;
        color: rgba(0, 0, 0, 0.87);
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  column-gap: 5px;
  & > button {
    svg {
      width: 15px;
      height: 15px;
      color: black;
    }
  }
}

.popper {
  padding: 15px 20px;
  width: 180px;
  position: relative;
  &.larger {
    width: 220px;
  }
  &__cross {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 10px;
    line-height: 5px;
  }
  .content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    &__row {
      font-size: 10px;
      font-family: $monserrat;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    padding: 110px 20px 20px;
    font-family: $monserrat;

    .pageHeader {
      flex-direction: column;
      justify-content: normal;
      align-items: flex-start;
      border-bottom: none;
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      margin: 0;
      z-index: 2;
      padding: 10px 20px;

      h1 {
        padding-left: 40px;
        padding-bottom: 10px;
        line-height: 40px;
        margin: 0;
      }

      &__controls {
        padding-bottom: 0;
        width: 100%;
        button {
          margin-left: 0;
          width: 100% !important;
        }
      }
    }

    .tableContainer {
      position: relative;
      left: -20px;
      width: 100vw;

      td {
        &.header {
          text-align: start;
          overflow: visible;
          & > span {
            position: sticky;
            left: 16px;
          }
        }
        &.total {
          text-align: start;
          overflow: visible;
          & > span {
            position: sticky;
            left: 16px;
          }
        }
      }
    }
  }
}
