@import 'assets/styles/variables.scss';

.selectInput {
  border: 1px solid transparent !important;
  &.invalid {
    border-color: $error_color !important;
  }
}

li.selectInput__menu {
  font-family: $monserrat;
  background: none !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

li.selectInput__emptyMenu {
  font-family: $monserrat;
  background: none !important;
  cursor: default;
}

svg.selectInput__icon {
  right: 10px;
  color: #000;
}

span.checkbox {
  margin-right: 20px;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .selectInput {
    width: calc(100vw - 40px) !important;
  }
  li.selectInput__menu {
    word-break: break-word !important;
    white-space: normal !important;
  }
}
