button.main_button {
  height: 30px;
}

.controls {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

table.logs_table {
  th {
    white-space: nowrap;

    &:nth-child(12) {
      text-align: center;
    }
  }
}

tr.logs_table_row {
  &:hover {
    & > td:last-child {
      .row_controls {
        display: flex;
      }
    }
  }

  & > td {
    max-width: 0;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }

    &.idCell {
      min-width: 85px;
    }
    &.typeCell {
      min-width: 160px;
    }
    &.locationCell {
      min-width: 240px;
    }
    &.destinationCell {
      min-width: 240px;
    }
    &.itemCell {
      min-width: 160px;
    }
    &.qtyCell {
      min-width: 95px;
    }
    &.packageCell {
      min-width: 130px;
    }
    &.palletCell {
      min-width: 105px;
    }
    &.dateCell {
      min-width: 130px;
    }
    &.carrierCell {
      min-width: 160px;
    }
    &.vendorCell {
      min-width: 160px;
    }
    &.fileCell {
      min-width: 70px;
    }
    &.createdByCell {
      min-width: 200px;
    }
    &.createdDateCell {
      min-width: 200px;
    }

    &.controlsCell {
      width: 70px;
      min-width: 70px;
      padding: 0;

      .row_controls {
        display: none;
        text-align: center;
        justify-content: center;
        padding: 5px 16px;
        column-gap: 10px;
        .icon_button {
          svg {
            color: black;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
