@import 'assets/styles/variables.scss';

.tableContainer {
  border-radius: 8px;
  &.stickyHeader {
    overflow-x: initial;
  }
  table {
    border-collapse: initial;
  }

  tr {
    &:hover {
      & > td:not(.whiteCell) {
        background-color: $hovered-row-color;
      }
    }
  }

  th {
    white-space: nowrap;
    &:last-child {
      width: 50px;
      min-width: 50px;
    }
    &.quantityHead,
    &.baaHead {
      padding: 8px 8px 8px 16px;
    }
  }

  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
    &.whiteCell {
      background-color: white !important;
      vertical-align: top;
    }
    &.itemCell {
      width: 30%;
      min-width: 350px;

      & > a {
        white-space: normal;
        word-break: break-all;
      }
    }
    &.locationCell {
      width: 20%;
      min-width: 200px;
    }
    &.sublocationCell {
      width: 20%;
      min-width: 200px;
    }
    &.quantityCell {
      width: 10%;
      min-width: 170px;
    }
    &.customCell {
      min-width: 200px;
    }
    &.baaCell {
      width: 10%;
      min-width: 160px;
    }
    &.statusCell {
      width: 10%;
      min-width: 160px;
      white-space: normal;
      word-break: break-word;
    }
    &.checkboxCell {
      width: 50px;
      min-width: 50px;
      padding: 0;
      text-align: center;
    }
  }

  .textInput {
    width: 100%;
    & > div {
      width: 100%;
      border: 1px solid #999;
      padding: 2px !important;
      font-size: 14px;
      border-radius: 8px;
      height: 34.625px;
    }
    & > svg {
      right: 5px;
    }

    fieldset {
      border: none;
    }
    input,
    textarea {
      font-family: $monserrat;
      font-weight: 400;
      padding: 8px;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      color: #000;

      &::placeholder {
        opacity: 1;
        color: #000;
      }
    }
    input {
      padding: 3px 5px;
    }
  }
  .helpers {
    display: flex;
    button {
      margin: 4px 0 0;
      flex: 1;
      border-radius: 4px;
      padding: 0;
      min-width: 20px;
      &:first-child {
        margin-right: 5px;
      }
      svg {
        height: 15px;
        padding: 3px;
      }
    }
    .alert {
      color: #fff;
      background: $alert_color;
      &:hover {
        background: #c47777;
      }
    }
    .success {
      color: #fff;
      background: $regular_color;
      &:hover {
        background: #5aa45a;
      }
    }
  }

  .countBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 15px;
    .counter {
      background: #ffffff;
      border: 0.5px solid #dcdcdc;
      border-radius: 8px;
      height: 20px;
      box-sizing: border-box;
      padding: 0 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: {
        family: $monserrat;
        size: 14px;
      }

      &:has(button) {
        padding: 0 2px 0 8px;
      }

      button {
        width: 20px;
        height: 20px;
        margin-left: 3px;
        svg {
          width: 100%;
          height: 100%;
          color: rgba(240, 23, 23, 1);
        }
      }
    }
  }

  .checkbox {
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .checked {
    color: $main_color !important;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  div.tableContainer {
    position: relative;
    left: -20px;
    width: 100vw;
    overflow-x: auto !important;

    td {
      &.itemCell {
        min-width: 200px;
      }
      &.quantityCell {
        min-width: 100px;
      }
      &.baaCell {
        min-width: 100px;
      }
    }
  }
}
