@import 'assets/styles/variables.scss';

.wrapper {
  width: 900px;
  box-sizing: border-box;
  display: flex;
  column-gap: 60px;
  margin-bottom: 30px;
  .block {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    row-gap: 20px;
    &__cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > label {
        font-family: $monserrat;
        margin: 0;
        font-size: 14px;
      }
      &_label {
        display: flex;
        align-items: center;
        font-family: $monserrat;
        margin: 0;
        font-size: 14px;
      }
      &_input {
        position: relative;
        width: 250px;
      }
      &_dates {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 335px;
        &_row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:first-child {
            margin-right: 15px;
          }
          & > label {
            font-family: $monserrat;
            margin: 0 10px 0 0;
            font-size: 14px;
          }
          &_picker {
            width: 125px;
          }
        }
      }
    }
    &__checkbox {
      display: flex;
      justify-content: flex-end;
      &_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 200px;
        & > label {
          font-family: $monserrat;
          margin: 0;
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
    .block {
      flex-direction: column;
      row-gap: 0;
      &:first-child {
        margin-bottom: 20px;
      }
      &__cell {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        & > label {
          margin: 0 0 10px 0;
        }
        &_label {
          margin: 0 0 10px 0;
        }
        &_input {
          width: 100%;
          & > div {
            width: 100%;
            & > div {
              width: 100%;
            }
          }
        }
        &_dates {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          &_row {
            width: 100%;
            &:first-child {
              margin: 0 0 20px;
            }
            & > label {
              width: 35px;
            }
            &_picker {
              width: calc(100% - 35px);
            }
          }
        }
      }
      &__checkbox {
        &_wrapper {
          width: 150px;
        }
      }
    }
  }
}
