@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 40px 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 1280px;
  margin: 0 auto;
  min-height: 100%;

  .pageHeader {
    margin: 0 0 20px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0;
    }
  }

  .pageForm {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    .firstBlock {
      display: flex;
      flex-direction: row;
      .firstRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        width: 427px;
        margin-right: 45px;
        .inputWrapper {
          position: relative;
          & > span {
            position: absolute;
            font-weight: 400;
            font-size: 10px;
            left: 0;
            bottom: -16px;
            color: $error_color;
          }
        }
      }
      .secondRow {
        width: 340px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .inputWrapper {
          position: relative;
          & > div {
            width: 150px;
          }
          & > span {
            position: absolute;
            font-weight: 400;
            font-size: 10px;
            left: 0;
            bottom: -16px;
            color: $error_color;
          }
        }
      }
    }
    .secondBlock {
      width: 427px;
    }
    .formRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      .inputWrapper {
        position: relative;

        & > span {
          position: absolute;
          font-weight: 400;
          font-size: 10px;
          left: 0;
          bottom: -16px;
          color: $error_color;
        }
      }
    }
    .notesRow {
      .multiTextInput {
        background: #f7f7f7;
        border-radius: 8px;
        width: 250px;
        margin: 0;
        border: 1px solid transparent;
        margin-top: 20px;

        & > div {
          & > div {
            padding: 10px 10px 25px !important;
          }
        }

        &.error {
          border-color: $error_color;
        }

        fieldset {
          border: none;
        }
        input,
        textarea {
          font-family: $monserrat;
          font-weight: 400;
          padding: 0;
          font-size: 14px;
          line-height: 14px;
          width: 100%;
          color: #000;

          &::placeholder {
            opacity: 1;
            color: #000;
          }
        }
        input {
          padding: 8px 10px;
        }
      }

      .multiTextInput {
        width: 427px;
        position: relative;

        textarea {
          width: 400px;
        }

        & > span {
          position: absolute;
          bottom: 8px;
          right: 8px;
          font-size: 10px;
          font-weight: 500;
        }

        &__counter {
          position: absolute;
          bottom: 8px;
          right: 8px;
          font-size: 10px;
          font-weight: 500;
        }
      }
    }
    .rowLabel {
      font-size: 14px;
      font-family: $monserrat;
      font-weight: 400;
    }
  }

  .toggle {
    margin-bottom: 20px;

    button {
      min-width: 125px;
    }

    span {
      font-size: 14px;
    }
  }

  .tableContainer {
    border-radius: 8px;
    margin-bottom: 10px;

    table {
      border-collapse: initial;
    }

    &.fixedHeader {
      overflow-x: initial;
    }

    td {
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
    }
    th {
      padding: 8px 5px;
    }

    .firstHeadCell {
      min-width: 200px;
      box-sizing: border-box;
    }
    .secondHeadCell {
      width: 180px;
      min-width: 180px;
      box-sizing: border-box;
    }
    .thirdHeadCell {
      width: 250px;
      min-width: 250px;
      box-sizing: border-box;
    }
    .fourthHeadCell {
      width: 150px;
      min-width: 150px;
      box-sizing: border-box;
    }
    .fifthHeadCell {
      width: 150px;
      min-width: 150px;
      box-sizing: border-box;
    }
    .moveBaaQtyHead {
      box-sizing: border-box;
      min-width: 180px;
      width: 180px;
    }
    .footnote {
      font-size: 9px;
      position: relative;
      top: -8px;
    }

    .firstRow {
      background-color: #fff;
      vertical-align: top;
      white-space: normal;
      word-break: break-word;
      .errorMessage {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        color: #e92d2d;
        white-space: normal;
        word-break: break-word;
      }
    }

    .redBackground {
      background-color: #ff000012 !important;
    }

    .secondRow {
      background-color: #fff;
      vertical-align: top;
    }

    .thirdRow {
      padding: 3px 0;
      & > div {
        width: 100%;
        min-width: 100%;
      }
    }

    .fourthRow {
    }

    .fifthRow {
      padding: 0 16px;
      text-overflow: initial;
    }
    .byAheadAccountRow {
      padding: 0 16px;
      text-overflow: initial;
    }
    .block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .fieldBlock {
        display: flex;
        flex-direction: column;
        & > div:first-child {
          width: 90px !important;
        }
        .errorMessage {
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          color: #e92d2d;
          margin-right: 10px;
          flex: 1;
          white-space: normal;
        }
      }
      .rowHelpers {
        display: flex;
        flex-direction: row;
        .addIcon {
          width: 20px;
          height: 20px;
          cursor: pointer;
          color: black;
        }
        .clearIcon {
          width: 20px;
          height: 20px;
          cursor: pointer;
          color: black;
          margin-left: 10px;
          background-color: transparent;
        }
        .clearIcon:hover {
          background-color: transparent;
        }
      }
    }
  }

  .hintLabel {
    font-style: italic;
    font-weight: 100;
    font-size: 14px;
    margin-bottom: 50px;
    display: block;
    opacity: 0.5;
  }

  .tableStyle {
    background: transparent !important;
    width: 100% !important;

    input {
      padding: 8px 0 !important;
    }
  }

  .noEditCell {
    pointer-events: none;
    cursor: default;
  }

  .textInput {
    background: #f7f7f7;
    border-radius: 8px;
    width: 250px;
    margin: 0;
    border: 1px solid transparent;

    &.error {
      border-color: $error_color;
    }

    fieldset {
      border: none;
    }

    input {
      font-family: $monserrat;
      font-weight: 400;
      padding: 8px 10px;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      color: #000;

      &::placeholder {
        opacity: 1;
        color: #000;
      }
    }
  }

  .footerControls {
    margin: 0;
    button {
      margin: 0 25px 0 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 70px 20px 20px;
    min-height: 100vh;
    height: auto;

    .pageHeader {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 10px 20px 10px;
      background: #fff;
      z-index: 5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-bottom: none;
      margin: 0;

      h1 {
        margin: 0 !important;
        padding-left: 40px;
        line-height: 40px;
      }
    }

    .pageForm {
      margin-bottom: 50px;
      .firstBlock {
        flex-direction: column;
        .firstRow {
          flex-direction: column;
          justify-content: normal;
          align-items: flex-start;
          margin-bottom: 20px;
          width: 100%;
          margin-right: 0;
          .inputWrapper {
            width: 100%;
            & > div {
              width: 100%;
              & > div {
                width: 100%;
              }
            }
          }
        }
        .secondRow {
          width: 100%;
          flex-direction: column;
          justify-content: normal;
          align-items: flex-start;
          margin-bottom: 20px;
          .inputWrapper {
            width: 100%;
            & > div {
              width: 100%;
              & > div {
                width: 100%;
              }
            }
          }
        }
      }
      .secondBlock {
        width: 100%;
      }
      .formRow {
        display: flex;
        flex-direction: column;
        justify-content: normal;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 20px;
        .inputWrapper {
          width: 100%;
          & > div {
            width: 100%;
            & > div {
              width: 100%;
            }
          }
        }
      }
      .notesRow {
        .multiTextInput {
          width: 100%;
        }

        .multiTextInput {
          width: 100%;

          textarea {
            width: 100%;
          }
        }
      }
      .rowLabel {
        margin-bottom: 10px;
      }
    }

    .textInput,
    .multiTextInput {
      width: 100%;
    }

    .tableContainer {
      position: relative;
      left: -20px;
      width: 100vw;
      td {
        text-overflow: initial;
      }
    }

    .footerControls {
      display: flex;
      justify-content: space-between;
      margin: 60px 0 0;
      button {
        width: 50%;
        min-width: 40%;
      }
      button:first-child {
        margin: 0 30px 0 0 !important;
      }
      button:last-child {
        margin: 0 !important;
      }
    }
  }
}
