@import 'assets/styles/variables.scss';

.mainInfoBlock {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  width: 100%;
  column-gap: 20px;
  .generalDetails {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 345px);
    flex: 1;
    .detailsBlock {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 15px;
      margin-bottom: 20px;
    }
  }
  .rightColumn {
    width: 345px;
    .editors {
      margin-bottom: 50px;
      &__info {
        font-size: 10px;
        font-style: italic;
        margin: 5px 0;
        padding: 0 10px;
      }
    }
  }
}
.detailsItem {
  display: block;
  width: 49%;
  label,
  span {
    font-style: normal;
    font-size: 14px;
    color: #000000;
    font-family: $monserrat;
  }
  & > label {
    font-weight: bold;
    white-space: normal;
    word-wrap: break-word;
    max-width: 40%;
  }
  & > span {
    margin-left: 10px;
    white-space: normal;
    word-break: break-all;
  }
}
.descriptionBlock {
  width: 100%;
}

.tableContainer {
  border-radius: 8px;
  margin-bottom: 15px;
  table {
    border-collapse: initial;
  }
  th {
    white-space: nowrap;
    border-right: none !important;
    &:not(:first-child) {
      border-left: 1px solid #dcdcdc;
    }
    @extend %table-cell-sizes;
  }
  td {
    -webkit-text-size-adjust: 100%;
    max-width: 0;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    &:not(:first-child) {
      border-left: 1px solid #dcdcdc;
    }
    &:nth-child(3),
    &:nth-child(4) {
      white-space: normal;
      word-break: break-word;
    }
    &.merged {
      background-color: white !important;
      vertical-align: top;
      padding: 9px 16px 8px;
    }
    @extend %table-cell-sizes;
  }

  .emptyTable {
    background-color: #f7f7f7;
    padding: 25px 0;
    font-size: 18px;
    text-align: center;
  }

  .footnote {
    font-size: 9px;
    position: relative;
    top: -8px;
  }
}
%table-cell-sizes {
  &:nth-child(1) {
    width: 15%;
    min-width: 190px;
  }
  &:nth-child(2) {
    width: 15%;
    min-width: 160px;
  }
  &:nth-child(3) {
    width: 20%;
    min-width: 200px;
  }
  &:nth-child(4) {
    width: 15%;
    min-width: 160px;
  }
  &:nth-child(5) {
    width: 11%;
    min-width: 90px;
  }
  &:nth-child(6) {
    width: 11%;
    min-width: 80px;
  }
  &:nth-child(7) {
    width: 13%;
    min-width: 130px;
  }
  &:nth-child(8) {
    width: 13%;
    min-width: 135px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .mainInfoBlock {
    flex-direction: column-reverse;
    .generalDetails {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      flex: 1;
      & > h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }
    .rightColumn {
      margin-bottom: 15px;
    }
  }

  .detailsItem {
    width: 100%;
  }

  .tableContainer {
    position: relative;
    left: -20px;
    width: 100vw !important;
  }
}
