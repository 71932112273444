@import 'assets/styles/variables.scss';

.header {
  margin: 0 0 -10px;
  .title {
    font-weight: 700;
    font-size: 24px;
    font-family: $monserrat;
    line-height: 30px;
    margin: 0;
  }
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    font-family: $monserrat;
    margin: 5px 0;
  }
}

.navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  & > div:first-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .header {
    margin: 0 0 30px;
  }

  .navigation {
    &__buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;
      column-gap: 40px;
      & > button {
        margin: 0;
        width: 100%;
      }
    }
  }
}
