@import 'assets/styles/variables.scss';

$cell-colors: (
  short: rgba(193, 222, 139, 0.5),
  medium: rgba(248, 211, 153, 0.5),
  longest: rgba(255, 173, 175, 0.5)
);

.wrapper {
  font-family: $monserrat;
  padding: 20px 60px 40px;

  .header {
    background: #fff;
    box-sizing: border-box;
    z-index: 1;

    &__controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 0;
    }
  }

  .table {
    border-radius: 8px;
    overflow-x: initial;

    table {
      border-collapse: initial;
    }

    th {
      white-space: nowrap;
      padding: 8px 8px 8px 16px;
      @extend %table-cell-sizes;
    }

    td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
      @each $status, $color in $cell-colors {
        &.#{$status} {
          background-color: $color;
        }
      }
      @extend %table-cell-sizes;
      &:nth-child(1) {
        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > a {
            max-width: calc(100% - 30px);
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        text-align: center;
      }
    }
  }
}

%table-cell-sizes {
  &:nth-child(1) {
    width: 20%;
    min-width: 180px;
  }
  &:nth-child(2) {
    width: 10%;
    min-width: 120px;
  }
  &:nth-child(3) {
    width: 10%;
    min-width: 120px;
  }
  &:nth-child(4) {
    width: 30%;
    min-width: 120px;
  }
  &:nth-child(5) {
    width: 10%;
    min-width: 125px;
  }
  &:nth-child(6) {
    width: 10%;
    min-width: 125px;
  }
  &:nth-child(7) {
    width: 10%;
    min-width: 125px;
  }
}

.tag {
  width: 26px;
  height: 12px;
  background-color: #d9d9d9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    font: {
      family: $monserrat;
      size: 8px;
      weight: 500;
    }
    text-transform: uppercase;
    color: black;
    line-height: 12px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    padding: 110px 20px 20px;

    .header {
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      margin: 0;
      z-index: 6;
      padding: 10px 20px 0;
      &__controls {
        flex-direction: row-reverse;
        padding: 0 0 10px 0;
      }
    }

    .table {
      position: relative;
      left: -20px;
      width: 100vw;
      overflow-x: auto;

      td,
      th {
        &:nth-child(1) {
          min-width: 130px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          min-width: 105px;
          padding: 8px;
        }
        &:nth-child(5),
        &:nth-child(6) {
          text-align: left;
        }
      }
      th {
        &:nth-child(2),
        &:nth-child(3) {
          white-space: break-spaces;
          word-break: break-word;
        }
      }
      td {
        &:nth-child(2),
        &:nth-child(3) {
          text-align: center;
        }
      }
    }
  }
}
