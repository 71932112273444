@import 'assets/styles/variables.scss';

.filterWrapper {
  display: flex;
  z-index: 10;

  span.filterButton {
    width: 34px;
    height: 34px;
    border-radius: 8px;
    background-color: #f6f6f6;

    &__opened,
    &__opened:hover {
      background-color: $main_color;

      img {
        filter: invert(100%);
      }
    }
  }
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 20px;
  padding: 30px 16px 20px;
  width: 900px;
  box-shadow: 0 0 4px 2px rgba(121, 165, 186, 0.15);
  box-sizing: border-box;
  border-radius: 8px !important;

  .formColumn,
  .formCell {
    display: flex;
  }

  .formColumn {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .formCell {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-basis: 47%;

    label {
      font-family: $monserrat;
      margin: 0;
      font-size: 14px;
    }
    .inputWrapper {
      width: 250px;
    }
  }

  .selectInput {
    height: 34.6px;
    padding: 8px 36px 6px 16px !important;
  }

  li.selectInput__menu {
    font-family: $monserrat !important;
    background: none !important;
  }

  svg.selectInput__icon {
    right: 10px;
    color: #000;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-end;

    .primary {
      display: flex;

      button {
        width: 196px;
        min-width: 196px;

        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}

.chipsBlock {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 0 20px;
  row-gap: 10px;
  box-sizing: border-box;
  z-index: -2;
  order: 5;

  .fieldsChip {
    background-color: #7aa5ba;
    border-radius: 8px;
    color: white;
    margin-right: 10px;

    font-family: $monserrat;

    & > span {
      padding: 6px 16px;
    }

    & > svg {
      color: #fff;
      height: 16px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .chipsBlock {
    padding: 0;
    width: 100%;
    z-index: initial;
  }

  .popper {
    width: 100vw;
    height: 100%;
    transform: translate3d(0, 0, 0) !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    overflow: auto;
  }

  .filterWrapper {
    span.filterButton {
      width: 32px;
      height: 32px;

      &__opened,
      &__opened:hover {
        background-color: $filters_secondary_color;
      }
    }
  }

  .filterContainer {
    display: flex;
    position: fixed;
    width: 100%;
    margin-left: 0;
    border-radius: 0 !important;
    transform: none !important;
    height: 100%;
    padding: 70px 20px 20px;
    overflow: auto;
    overscroll-behavior: none;

    .formColumn {
      justify-content: space-between;
      width: 100%;
    }

    .formCell {
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
      flex-basis: 100%;

      label {
        margin: 0 0 10px 0;
      }

      .inputWrapper {
        width: 100%;
      }

      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 3;
      }
      &:nth-child(3) {
        order: 2;
      }
      &:nth-child(4) {
        order: 4;
      }
    }
    .buttonWrapper {
      align-items: flex-end;
      margin: 20px 0 0;
      width: 100%;
      .primary {
        width: 100%;
        button {
          width: 50%;
          min-width: 40%;
        }
        button:first-child {
          margin: 0 30px 0 0 !important;
        }
        button:last-child {
          margin: 0 !important;
        }
      }
    }
  }
}
