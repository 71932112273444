@import 'assets/styles/variables.scss';

.popup {
  width: fit-content;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $monserrat;

  .content {
    margin-bottom: 50px;
    .form {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      &__assets,
      &__customs,
      &__notes {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }

      &__row,
      &__checkbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__customs {
        margin-bottom: 15px;
      }

      &__assets {
        width: 850px;
      }

      &__row,
      &__notes {
        width: 427px;
      }

      &__checkbox {
        width: 320px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 427px;
    margin-top: auto;
    position: relative;
    & > button {
      width: 46% !important;
      min-width: 46% !important;
      height: 40px;
    }

    & > span {
      position: absolute;
      bottom: -18px;
      left: -14px;
      text-align: end;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: $error_color;
      font-family: Montserrat, sans-serif;
      width: 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .popup {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px;

    .content {
      .form {
        &__row {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          row-gap: 10px;
        }

        &__customs {
          margin-bottom: 0;
        }

        &__assets,
        &__row,
        &__notes {
          width: 100%;
        }

        &__checkbox {
          width: 100%;
          justify-content: flex-end;
          column-gap: 50px;
        }
      }
    }

    .footer {
      width: 100%;

      & > span {
        bottom: 50px;
        right: 0;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
}
