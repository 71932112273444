@import 'assets/styles/variables.scss';

.tableContainer {
  border-radius: 8px;
  margin-bottom: 15px;
  table {
    border-collapse: initial;
  }
  th {
    padding: 2px 8px;
  }
  td {
    -webkit-text-size-adjust: 100%;
    padding: 8px 12px;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }

  .checkboxHead {
    box-sizing: border-box;
    min-width: 50px;
    width: 50px;
    .checkbox {
      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
    .checked {
      color: $main_color !important;
      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }
    }
  }

  .itemsHead {
    box-sizing: border-box;
  }

  .locationHead {
    box-sizing: border-box;
    min-width: 260px;
    width: 260px;
  }

  .qtyHead {
    box-sizing: border-box;
    min-width: 100px;
    width: 100px;
  }

  .destinationHead {
    box-sizing: border-box;
    min-width: 155px;
    width: 155px;
  }

  .moveQtyHead {
    box-sizing: border-box;
    min-width: 100px;
    width: 100px;
  }

  .moveBaaQtyHead {
    box-sizing: border-box;
    min-width: 105px;
    width: 105px;
  }

  .lastHead {
    box-sizing: border-box;
    min-width: 50px;
    width: 50px;
  }

  .footnote {
    font-size: 9px;
    position: relative;
    top: -8px;
  }
}

.hintLabel {
  font-style: italic;
  font-weight: 100;
  font-size: 14px;
  margin-bottom: 15px;
  display: block;
}

@media screen and (max-width: $breakpoint-1180) {
  .tableContainer {
    position: relative;
    left: -20px;
    width: 100vw !important;
  }
}
