@import 'assets/styles/variables.scss';

.item {
  width: 100%;
  height: 60px;
  padding: 0 15px 0 18px;
  color: white;
  display: grid;
  grid-template-columns: 22px 1fr 22px;
  align-items: center;
  box-sizing: border-box;

  &.active,
  &:hover {
    background-color: $sidebar_item_active_background;
  }

  &:hover {
    cursor: pointer;
  }

  &.inCollapse {
    .item__label {
      font-size: 12px;
    }
  }

  &__icon {
    height: 22px;
    & img {
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    font-family: $monserrat;
    white-space: nowrap;
    margin-left: 20px;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 23px;

    button.item__controls_btn {
      width: 23px;
      height: 23px;
      border-radius: 0;
      &:hover {
        background-color: $sidebar_item_active_background;
      }

      & svg {
        width: 23px;
        height: 23px;
        fill: white;
      }

      & img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
