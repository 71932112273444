@import 'assets/styles/variables.scss';

.tableContainer {
  border-radius: 8px;
  margin-bottom: 50px;

  @media screen and (max-width: $breakpoint-1180) {
    position: relative;
    left: -20px;
    width: 100vw !important;
  }

  table {
    border-collapse: initial;
  }

  tr {
    display: grid;
    grid-template-columns: minmax(200px, 20%) minmax(200px, 13%) minmax(200px, 17%) minmax(
        200px,
        50%
      );
  }

  td {
    vertical-align: top;
    white-space: normal;
    word-break: break-all;

    -webkit-text-size-adjust: 100%;
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
  }
  th {
    padding: 8px 16px;
  }

  .rowPrefix {
    background-color: #fff !important;
  }

  .rowQTY {
    background-color: #fff !important;
  }

  .rowAssets {
    display: grid;
    padding: 0;

    background-color: #fff !important;
  }

  .selectInput {
    height: auto;
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    &Highlighted {
      background: #f7f7f7;
    }

    div:nth-child(1) {
      width: 100%;
      max-width: none;

      border-radius: 0;
      background: transparent;
    }
  }

  .selectInput:nth-last-child(1) {
    border-bottom: none;
  }

  .errorMessage {
    left: 10px;
    bottom: 10px;
    z-index: 999;
  }

  .rowOrigin {
    display: grid;
    padding: 0;

    background-color: #fff !important;

    .location {
      display: flex;
      align-items: center;
      min-height: 36px;
      padding: 0 16px;

      border-bottom: 1px solid rgba(224, 224, 224, 1);

      &:nth-last-child(1) {
        border-bottom: none;
      }

      &Highlighted {
        background: #f7f7f7;
      }
    }
  }
}
