@import 'assets/styles/variables.scss';

.counts {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  width: 220px;
  &__row {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &_status,
    &_number {
      font-family: $monserrat;
      font-size: 14px;
    }
    &_status {
      &.total {
        font-weight: 700;
      }
    }
    &_number {
      font-weight: 700;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .counts {
    padding-top: 0;
    padding-bottom: 20px;
    width: 100%;
  }
}
