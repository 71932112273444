@import 'assets/styles/variables.scss';

button.add_button,
button.add_column_button {
  font-family: $monserrat;
  color: #fff;
  background-color: $main_color;
  border-radius: 8px;
  width: 150px;
  font-size: 12px;
  font-weight: 500;
  height: 35px;
  transition: all 0.3ms ease-out;
  padding-left: 12px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: $main_color;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  svg {
    margin-right: 7px;
    width: 17px;
    height: 17px;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  button.add_button,
  button.add_column_button {
    width: 100%;
  }
  button.add_column_button {
    margin-bottom: 15px;
  }
}
