@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 30px 60px 20px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;

  .headerBlock {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $main_color;
    padding: 0 0 10px;
    margin: 0 0 30px;

    button {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      cursor: pointer;
      font-family: $monserrat;
      margin: 0 0 0 25px;
      padding: 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      outline: none;
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin: 0;
    }

    &__controls {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        color: black;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-bottom: 50px;
  }

  .toggle {
    margin-bottom: 20px;

    button {
      min-width: 125px;
    }

    span {
      font-size: 14px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    &Single {
      justify-content: flex-end;
    }

    .hintLabel {
      font-style: italic;
      font-weight: 100;
      font-size: 14px;
      display: block;
      opacity: 0.5;
    }

    .tableFooter {
      display: flex;
      align-items: center;
      .checkboxRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-right: 30px;

        .checkboxWrapper {
          width: 24px;

          .checkbox {
            &:hover,
            &:active {
              background-color: rgba(0, 0, 0, 0.04);
            }
          }
          .checked {
            color: $main_color;
            &:hover,
            &:active {
              background-color: rgba(0, 0, 0, 0.04);
            }
          }
          .disabled {
            cursor: default;
            color: #e6e6e6 !important;
          }
        }

        & > label {
          font-size: 14px;
          font-family: $monserrat;
          font-weight: 400;
          margin-right: 25px;
        }
      }
      .inputWrapper {
        position: relative;
      }
    }
  }

  .footerControls,
  .singleFooterControl {
    margin: 0;
    button {
      margin: 0 25px 0 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 60px 20px 0;
    height: 100vh;

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin: 20px 0 30px;
      white-space: normal;
      word-break: break-all;
    }

    .headerBlock {
      border-bottom: none;
      position: fixed;
      height: 60px;
      padding: 10px 20px 0;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      z-index: 1;
      margin: 0;

      button {
        background: #f6f6f6;
        border-radius: 8px;
        height: 30px;
        width: 30px;
        justify-content: center;
      }

      &__controls {
        position: fixed;
        right: 20px;
        top: 20px;

        svg {
          margin-right: 0;
        }
      }
    }

    .contentWrapper {
      & > h1 {
        margin: 0 0 10px;
      }
    }

    .tableContainer {
      position: relative;
      left: -20px;
      width: 100vw;
    }

    .singleFooterControl {
      margin-top: 40px;
      padding-bottom: 20px;
      button {
        width: 100%;
        min-width: 90%;
      }
    }

    .footer {
      flex-direction: column;
      justify-content: normal;
      align-items: flex-start;
      margin-bottom: 0;

      .hintLabel {
        font-style: italic;
        font-weight: 100;
        font-size: 14px;
        display: block;
        opacity: 0.5;
      }

      .tableFooter {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        .checkboxRow {
          display: flex;
          justify-content: space-between;
          margin-right: 0;
          width: 100%;
          margin-bottom: 15px;
        }
        .inputWrapper {
          width: 100%;
          & > div {
            width: 100%;
            & > div {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
