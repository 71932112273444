@import 'assets/styles/variables.scss';

.pageWrapper {
  font-family: $monserrat;
  padding: 20px 60px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 60px);
  box-sizing: border-box;
}
.pageContainer {
  .headerWrapper {
    background: #fff;
    box-sizing: border-box;
    z-index: 1;

    .tableControls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
    }
  }

  .tableContainer {
    border-radius: 8px;

    table {
      border-collapse: initial;
    }

    tr {
      &:hover {
        & > td {
          background-color: $hovered-row-color;
        }
      }
    }

    td {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
      &:nth-child(1) {
        width: 20%;
        min-width: 200px;
      }
      &:nth-child(2) {
        width: 20%;
        min-width: 200px;
      }
      &:nth-child(3) {
        width: 20%;
        min-width: 200px;
      }
      &:nth-child(4) {
        width: 20%;
        min-width: 200px;
      }
      &:nth-child(5) {
        width: 20%;
        min-width: 200px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageWrapper {
    font-family: $monserrat;
    padding: 170px 20px 20px;
    min-height: 100vh;
    .pageContainer {
      .headerWrapper {
        position: fixed;
        padding-top: 10px;
        top: 0;
        left: 0;
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        z-index: 5;
        padding-right: 20px;
        padding-left: 20px;
        .tableControls {
          flex-direction: row-reverse;
          padding: 20px 0 10px 0;
          &.fitControls {
            padding: 0 0 10px 0;
          }
        }
      }

      .tableContainer {
        position: relative;
        left: -20px;
        width: 100vw;
        overflow-x: auto;
      }
    }
    &.fitWrapper {
      padding: 110px 20px 20px;
    }
  }
}
