@import 'assets/styles/variables.scss';

.controls {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;

  &.onlyChild {
    justify-content: flex-end;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .controls {
    flex-direction: row-reverse;
    column-gap: 20px;

    &:has(div:only-child) {
      justify-content: space-between;
    }
  }
}
