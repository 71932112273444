@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 20px 60px 40px;

  .tableControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }

  .tableContainer {
    border-radius: 8px;
    overflow-x: initial;
    table {
      border-collapse: initial;
    }

    tr {
      &:hover {
        & > td {
          background-color: $hovered-row-color;
        }
      }
    }

    td {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
      &:nth-child(1) {
        width: 15%;
        min-width: 200px;
      }
      &:nth-child(2) {
        width: 15%;
        min-width: 200px;
      }
      &:nth-child(3) {
        width: 10%;
        min-width: 200px;
        white-space: normal;
        word-break: break-word;
      }
      &:nth-child(4) {
        width: 30%;
        min-width: 400px;
      }
      &:nth-child(5) {
        width: 10%;
        min-width: 160px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 170px 20px 20px;

    .headerWrapper {
      position: fixed;
      padding-top: 10px;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      margin: 0;
      z-index: 1;
      padding-right: 20px;
      padding-left: 20px;
    }

    .tableControls {
      flex-direction: row-reverse;
      padding: 20px 0 10px 0;
    }

    .tableContainer {
      position: relative;
      left: -20px;
      width: 100vw;
      overflow-x: auto;
    }
  }
}
