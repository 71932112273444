@import 'assets/styles/variables.scss';

.headerContainer {
  position: relative;
  height: 60px;
  box-sizing: border-box;
  padding: 10px 15px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  z-index: 5;
  display: flex;
  align-items: center;

  button.headerContainer__logo {
    &:hover {
      background-color: transparent;
    }
  }

  .quick_buttons {
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
  }

  .headerTitle {
    color: red;
    font-weight: bold;
    text-transform: uppercase;
  }

  .rightBlock {
    display: flex;
    align-items: center;
    margin-left: auto;
    .versionText {
      font-family: $monserrat;
      letter-spacing: 3px;
      font-size: 12px;
      padding: 0 5px 0;
      color: #999;
      cursor: default;
      margin-right: 15px;
    }

    .profileBlock {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      cursor: pointer;
      & > svg {
        width: 30px;
        height: 30px;
        color: #acafc3;
      }
    }
  }
}
