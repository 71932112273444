@import 'assets/styles/variables.scss';

.downloadButton {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  font-family: $monserrat;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  outline: none;
  padding: 5px;
  height: 30px;
  border-radius: 8px;
  color: black;

  img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
