@import 'assets/styles/variables.scss';

$header-row-z-index: 3;
$sticky-column-z-index: 2;
$resizer-z-index: 1;

div.tooltip {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: white;
  color: black;
  box-shadow: $shadow;
  opacity: 1;
  max-width: 700px;
  white-space: normal;
  word-break: break-word;
}

.container {
  overflow-x: auto;
  box-shadow: $shadow;
  border-radius: 8px;
  max-height: calc(100vh - 60px - 40px - (36px + 20px));

  .table {
    .head,
    .body {
      .row {
        min-height: 40px;
        display: flex;

        .th,
        .td {
          padding: 8px 16px;
          box-sizing: border-box;
        }
      }
    }

    .head {
      position: sticky;
      top: 0;
      z-index: $header-row-z-index;
      .row {
        .th {
          font: {
            size: 14px;
            weight: 700;
          }
          position: relative;
          text-align: center;
          height: 40px;
          vertical-align: middle;
          display: flex;
          align-items: center;
          background-color: #f7f7f7;
          color: black;
          border-bottom: 1px solid #dcdcdc;

          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }

          &.centered {
            justify-content: center;
          }

          &.sticky {
            position: sticky;
            left: 0;
            z-index: $sticky-column-z-index;
            border-right: 1px solid #dcdcdc;
          }
        }
      }
    }

    .body {
      .row {
        &:nth-of-type(even) {
          & > .td {
            background-color: #f7f7f7;
          }
        }
        &:nth-of-type(odd) {
          & > .td {
            background-color: white;
          }
        }

        &:not(:last-child) {
          & > .td {
            border-bottom: 1px solid #dcdcdc;
          }
        }

        &:hover {
          & > .td {
            background-color: rgb(222, 239, 253);
          }
        }

        .td {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          vertical-align: middle;
          line-height: 24px;

          &.centered {
            text-align: center;
          }

          &.sticky {
            position: sticky;
            left: 0;
            z-index: $sticky-column-z-index;
            border-right: 1px solid #dcdcdc;
          }

          &.editable {
            cursor: pointer;
          }
        }
      }

      .emptyRow {
        .emptyTd {
          background-color: #f7f7f7;
          font-size: 18px;
          padding: 25px 0 !important;
          text-align: center;
        }
      }
    }
  }
}

.resizer {
  position: absolute;
  z-index: $resizer-z-index;
  height: 100%;
  width: 8px;
  top: 0;
  right: 0;
  cursor: ew-resize;
  user-select: none;
  touch-action: none;

  &::after {
    content: '';
    position: absolute;
    z-index: $resizer-z-index;
    display: block;
    border-right: 2px solid #dcdcdc;
    height: 30%;
    top: calc(50% - 30% * 0.5);
    right: 0;
  }
}

.resizer.isResizing {
  border-right: 1px solid #dcdcdc;
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    position: relative;
    left: -20px;
    width: 100vw;

    .container {
      max-height: calc(100vh - 100px - 20px - 106px);
    }
  }
}
