@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 160px 60px 40px;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;

  .headerWrapper {
    position: fixed;
    padding-top: 100px;
    background: #fff;
    width: calc(100vw - 120px - 60px);
    max-width: 1160px;
    box-sizing: border-box;
    z-index: 1;
    top: 0;

    .pageHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h1 {
        font-weight: 700;
        font-size: 24px;
        margin: 0;
      }
    }

    .searchPanel {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 20px 0 25px;
    }
  }

  .accordionTitle {
    min-width: 400px;
    max-width: 400px;
    font-weight: 700;
    text-decoration: underline;
    line-height: 25px;
    white-space: nowrap;
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.87);
  }

  .accordionButton {
    background: $main_color;
    color: #fff;
    font-family: $monserrat;
    font-weight: 400;
    text-transform: capitalize;
    min-height: 25px;
    padding: 0;
    border-radius: 8px;
    width: 106px;

    &:hover {
      background: $main_color;
    }
  }

  .accordionIcon {
    padding: 12px;
  }

  .rowQuantity {
    padding-right: 20px;
  }

  .firstCell {
    width: 100%;
  }

  .emptyResponseBlock {
    text-align: center;
    padding-top: 50px;
    & > span {
      font-size: 24px;
      font-family: $monserrat;
    }
  }

  .table {
    border-collapse: initial;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 120px 20px 0;

    .headerWrapper {
      position: fixed;
      padding: 10px 20px 0;
      top: 0;
      left: 0;
      background: #fff;
      width: 100%;
      margin: 0;
      box-sizing: border-box;
      z-index: 1;

      .pageHeader {
        h1 {
          padding-left: 40px;
          line-height: 40px;
        }
        button {
          position: absolute;
          right: 15px;
        }
      }

      & > .searchPanel {
        margin-bottom: 10px;
      }
    }

    .accordionTitle {
      min-width: auto;
      max-width: calc(100vw - 190px);
      font-size: 14px;
      padding: 5px 10px 5px 0;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;
    }

    .accordionButton {
      max-height: 35px;
      width: 80px;
    }

    .firstCell {
      vertical-align: top;

      a {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
