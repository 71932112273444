@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 0 60px 40px;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;

  .headerWrapper {
    padding-top: 40px;
    background: #fff;
    width: calc(100vw - 120px - 61px);
    max-width: 1160px;
    box-sizing: border-box;
    z-index: 1;
  }

  .pageHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0;
    }

    .headerControls {
      display: flex;
      column-gap: 20px;
    }
  }

  .searchPanel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 20px 0 25px;
  }

  .accordionTitle {
    min-width: 230px;
    max-width: 230px;
    font-weight: 700;
    text-decoration: underline;
    line-height: 25px;
    white-space: nowrap;
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.87);
  }

  .accordionButton {
    background: $main_color;
    color: #fff;
    font-family: $monserrat;
    font-weight: 400;
    text-transform: lowercase;
    text-transform: capitalize;
    min-height: 25px;
    padding: 0;
    border-radius: 8px;
    width: 106px;

    &:hover {
      background: $main_color;
    }
  }

  .accordionIcon {
    padding: 12px;
  }

  .rowName {
    font-weight: 700;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.87);
  }

  .rowLink {
    font-weight: 500;
    font-size: 9px;
    color: #000;
    cursor: pointer;
    text-decoration: underline;
  }

  .table {
    border-collapse: initial;

    td {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      font-size: 14px;

      &.prefixCell {
        width: 235px;
        min-width: 235px;
      }
      &.descCell {
        width: auto;
      }
      &.lastCell {
        width: 120px;
        min-width: 120px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 130px 20px 20px;

    .headerWrapper {
      position: fixed;
      padding-top: 10px;
      top: 0;
      background: #fff;
      width: calc(100vw - 40px);
      box-sizing: border-box;
      z-index: 1;

      h1 {
        padding-left: 40px;
        line-height: 40px;
      }
    }

    .accordionTitle {
      min-width: auto;
      max-width: calc(100vw - 210px);
      font-size: 14px;
      padding: 5px 10px 5px 0;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;
    }

    .accordionButton {
      max-height: 35px;
    }

    .table {
      td {
        &.descCell {
          max-width: 640px;
        }
      }
    }
  }
}
