@import 'assets/styles/variables.scss';

.filterWrapper {
  display: flex;
  z-index: 10;
}

.popper {
  z-index: 2;
}

span.filterButton {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  background-color: #f6f6f6;

  &__opened,
  &__opened:hover {
    background-color: $main_color;

    img {
      filter: invert(100%);
    }
  }
}

.filterContainer {
  margin-left: 20px;
  padding: 30px 20px 20px;
  box-shadow: 0 0 4px 2px rgba(121, 165, 186, 0.15);
  box-sizing: border-box;
  border-radius: 8px !important;

  .formColumn,
  .formCell,
  .checkboxCell {
    display: flex;
  }

  .formColumn {
    flex-direction: column;
  }

  .formCell,
  .checkboxCell {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    & > label {
      font-family: $monserrat;
      margin: 0;
      font-size: 14px;
    }

    .inputWrapper {
      width: 250px;
      text-align: center;
    }
  }

  .checkbox {
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .checked {
    color: $main_color !important;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-end;

    .primary {
      display: flex;
      button {
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}

.chipsBlock {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: -2;

  .fieldsChip {
    background-color: #7aa5ba;
    border-radius: 8px;
    color: white;
    font-family: $monserrat;
    max-width: 100%;

    & > span {
      padding: 6px 16px;
    }

    & > svg {
      color: #fff;
      height: 16px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .chipsBlock {
    padding: 0 20px;
    z-index: initial;
  }

  .popper {
    width: 100vw;
    height: 100%;
    transform: translate3d(0, 0, 0) !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    overflow: auto;
    z-index: 6 !important;
  }

  span.filterButton {
    width: 32px;
    height: 32px;

    &__opened,
    &__opened:hover {
      background-color: $filters_secondary_color;
    }
  }

  .filterContainer {
    display: flex;
    position: fixed;
    width: 100%;
    margin-left: 0;
    border-radius: 0 !important;
    transform: none !important;
    height: 100%;
    padding: 0;
    overflow: auto;

    .formColumn {
      flex-direction: column;
      width: 100%;
      padding-top: 70px;
      height: calc(100% - 70px);
      overflow: auto;
      overscroll-behavior: none;
    }

    .formCell {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 20px;

      & > label {
        margin-bottom: 10px;
      }

      .inputWrapper {
        width: 100%;
      }
    }

    .checkboxCell {
      padding: 0 20px;
      justify-content: flex-end;
      & > label {
        margin-right: 20px;
      }
      .inputWrapper {
        width: auto;
      }
    }

    .buttonWrapper {
      align-items: flex-end;
      margin: auto 0 0;
      padding: 0 20px 20px;
      .primary {
        width: 100%;
        button {
          width: 50%;
          min-width: 40%;
        }
        button:first-child {
          margin: 0 30px 0 0 !important;
        }
        button:last-child {
          margin: 0 !important;
        }
      }
    }

    .checkbox {
      margin-right: 0;
    }
  }
}
