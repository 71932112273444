@import 'assets/styles/variables.scss';

.filterWrapper {
  display: flex;
  z-index: 10;
}

span.filterButton {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  background-color: #f6f6f6;

  &__opened,
  &__opened:hover {
    background-color: $main_color;

    img {
      filter: invert(100%);
    }
  }
}

.filterContainer {
  margin-left: 20px;
  padding: 30px 16px 20px;
  box-shadow: 0px 0px 4px 2px rgba(121, 165, 186, 0.15);
  box-sizing: border-box;
  border-radius: 8px !important;

  .formColumn,
  .formCell {
    display: flex;
  }

  .formColumn {
    flex-direction: column;
  }

  .formCell {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    label {
      font-family: $monserrat;
      margin: 0;
      font-size: 14px;
    }

    .inputWrapper {
      width: 250px;
    }

    &.checkboxCell {
      .inputWrapper {
        width: auto;
      }
    }
  }

  .checkbox {
    margin-right: 120px;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .checked {
    color: $main_color !important;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-end;

    .primary {
      display: flex;
      button {
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}

span.checkbox {
  margin-right: 20px;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.checked {
  color: $main_color !important;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.chipsBlock {
  flex-grow: 1;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: -2;

  .fieldsChip {
    background-color: #7aa5ba;
    border-radius: 8px;
    color: white;
    margin-right: 10px;
    font-family: $monserrat;

    & > span {
      padding: 6px 16px;
    }

    & > svg {
      color: #fff;
      height: 16px;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .chipsBlock {
    padding: 0;
    z-index: initial;

    .fieldsChip {
      margin-bottom: 10px;
    }
  }

  .popper {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw;
    height: 100%;
    transform: none !important;
    overflow: hidden;
    box-sizing: border-box;
  }
  .wFull {
    width: 100%;
    & > div {
      width: 100%;
    }
  }

  .filterContainer {
    margin-left: 0;
    border-radius: 0 !important;
    transform: none !important;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 70px 20px 20px;
    box-sizing: border-box;

    .formColumn {
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }

    .formCell {
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;

      label {
        margin-bottom: 10px;
      }

      &.checkboxCell {
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;

        label {
          margin: 0 40px 0 0;
        }
      }

      .inputWrapper {
        width: 100%;
      }
    }

    .buttonWrapper {
      margin: auto 0 0;
      width: 100%;
      .primary {
        width: 100%;
        button {
          width: 50%;
          min-width: 40%;
        }
        button:first-child {
          margin: 0 30px 0 0 !important;
        }
        button:last-child {
          margin: 0 !important;
        }
      }
    }

    .checkbox {
      margin-right: 0;
    }
  }

  span.filterButton {
    width: 32px;
    height: 32px;

    &__opened,
    &__opened:hover {
      background-color: $filters_secondary_color;
    }
  }
}
