@import 'assets/styles/variables.scss';

button.add_btn {
  width: 50px;
  min-width: 50px;

  & svg {
    margin-right: 0;
  }

  &.disabled {
    color: white;
    opacity: 0.5;
    pointer-events: none;
  }
}

.pageContainer {
  font-family: $monserrat;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: calc(100vh - 60px);
  height: auto;

  .pageContent {
    .pageHeader {
      display: flex;
      border-bottom: 1px solid $main_color;
      padding-bottom: 10px;
      margin-bottom: 20px;
      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        margin: 0;
      }
    }
  }

  .footerControls {
    margin: 40px 0 0;
    button {
      margin: 0 25px 0 0;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  button.add_btn {
    position: relative;
    left: -20px;
  }

  .pageContainer {
    font-family: $monserrat;
    padding: 70px 20px 20px;
    min-height: 100vh;
    height: auto;

    .pageContent {
      .pageHeader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        padding: 10px 20px 0;
        background: #fff;
        z-index: 5;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        box-sizing: border-box;
        border-bottom: none;
        margin: 0;

        h1 {
          margin: 0 !important;
          padding-left: 40px;
          line-height: 40px;
        }

        button {
          display: flex;
          border: none;
          background: none;
          cursor: pointer;

          & > svg,
          & > img {
            margin: 5px -14px 0 0;
            width: 30px;
            height: 30px;
          }
        }
      }

      .mainButton {
        margin-bottom: 20px;
        button {
          margin: 0;
          min-width: 100%;
          width: 100%;
        }
      }
    }

    .footerControls {
      button {
        margin: 0;
        min-width: 100%;
        width: 100%;
      }
    }
  }
}
