@import 'assets/styles/variables.scss';

.form {
  & > h2 {
    font-family: $monserrat;
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 25px 0;
  }
  .general {
    display: flex;
    margin-bottom: 50px;
    &.disableMargin {
      margin-bottom: 20px;
    }
    &__block {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      &:first-child {
        margin-right: 100px;
      }
      &_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 427px;
        & > label {
          font-family: $monserrat;
          font-size: 14px;
        }

        &.checkbox {
          width: 320px;
        }
      }
      &_input {
        width: 250px;
      }
    }
  }

  .professional {
    display: flex;
    flex-direction: column;
    row-gap: 45px;
    margin-bottom: 50px;

    &__cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 770px;
      & > label {
        font-family: $monserrat;
        font-size: 14px;
      }
    }
    .project {
      width: 640px;
    }
  }

  .notes {
    width: 427px;
    margin-bottom: 70px;
  }
}

div.inputs__project {
  width: 420px;
  & > div {
    & > div {
      background: white;
      border: 1px solid #507a8e;
      border-radius: 8px;
    }

    svg {
      color: #507a8e;
    }
    button {
      margin-right: 5px !important;
    }
  }
}
div.inputs__tip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > svg {
    font-size: 12px;
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
  & > span {
    font-size: 10px;
  }
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100% - 25px - 16px);
  width: 100%;
  & > span {
    font-size: 14px;
    font-family: $monserrat;
    margin-left: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 25px - 35px);
  }
  button.edit_option_button {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    font-family: $monserrat;
    margin: 0 0 0 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    outline: none;
    color: #aebbc1;
    border-radius: 8px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.radio {
  margin: 0 !important;
}

.license {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 770px;
  &__chip {
    max-width: calc(100% - 300px - 15px);
  }
  &__dates {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    & > label {
      font-size: 14px;
      font-family: $monserrat;
      margin-right: 10px;
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .form {
    .general {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
      width: 100%;
      margin-bottom: 30px;
      &__block {
        width: 100%;
        &:first-child {
          margin-right: 0;
        }
        &_row {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          & > label {
            margin-bottom: 10px;
          }

          &.checkbox {
            & > label {
              margin-bottom: 0;
            }

            width: 100%;
            justify-content: flex-end;
            flex-direction: row;
            align-items: center;
            column-gap: 50px;
          }
        }
        &_input {
          width: 100%;
        }
      }
    }

    .professional {
      row-gap: 30px;
      &__cell {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        & > label {
          margin-bottom: 10px;
        }
      }
      .project {
        width: 100%;
      }
    }

    div.inputs__project {
      width: 100%;
    }
    div.inputs__tip {
      margin-top: 3px;
    }

    .notes {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .license {
    width: 100%;
    &__chip {
      max-width: calc(100% - 130px - 15px);
    }
    &__dates {
      width: 130px;
    }
  }

  .radio {
    & > span {
      display: block !important;
    }
  }
}
