@import 'assets/styles/variables.scss';

div.bar {
  border-radius: 8px 8px 0 0;
  margin-bottom: 10px;
  overflow-y: auto;
  width: fit-content;
  button.bar__button {
    font-family: $monserrat;
    font-size: 18px;
    font-weight: 600;
    flex-grow: 1;
    padding: 5px 15px;
    border: none;
    color: #000;
    background: #f7f7f7;
    text-transform: none;
    white-space: nowrap;

    &:first-child {
      border-radius: 8px 0 0 0;
    }
    &:last-child {
      border-radius: 0 8px 0 0;
    }
    &:only-child {
      border-radius: 8px 8px 0 0;
    }
  }

  button.selected,
  button.selected:hover {
    color: #fff;
    background: $main_color;
  }

  button.disabled,
  button.disabled:hover {
    opacity: 0.5;
    pointer-events: none;
  }
}

div.tabs {
  margin-bottom: 20px;
  height: 25px;
  &__root {
    border-bottom: 1px solid $main_color;
    height: 100%;
    min-height: 100%;
    & > div {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }
  span.tabs__indicator {
    background-color: $main_color;
  }
  button.tabs__item {
    height: 100%;
    min-height: 100%;
    text-transform: capitalize;
    color: $main_color;
    text-align: center;
    font-family: $monserrat;
    font-size: 14px;
    font-weight: 600;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  div.bar {
    width: 100%;
    button.bar__button {
      font-size: 14px;
      flex-grow: 1;
    }
  }

  div.tabs {
    button.tabs__item {
      min-width: 90px;
    }
  }
}
