@import 'assets/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  .form {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    row-gap: 20px;

    &__assets {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      width: 850px;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 470px;
      height: 33.6px;
    }

    &__fields {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      margin-bottom: 40px;

      .asset_field_row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(470px - 25px);

        & > label {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(470px - 260px);
          white-space: nowrap;
        }

        &__controls {
          position: relative;
          &_input {
            width: calc(250px - 25px);
          }
          &_clear {
            position: absolute;
            right: -25px;
            top: 9px;
            .clearIcon {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }

  .footer {
    margin-top: auto;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    .form {
      &__assets {
        width: 100%;
      }

      &__row {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: 10px;
        height: auto;
      }

      &__fields {
        width: 100%;

        .asset_field_row {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          row-gap: 10px;

          & > label {
            width: 100%;
            max-width: 100%;
          }

          &__controls {
            display: flex;
            column-gap: 20px;
            width: 100%;
            &_input {
              width: calc(100% - 25px);
            }
            &_clear {
              position: relative;
              top: 0;
              left: 0;

              .clearIcon {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }

    .footer {
      & > button {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  }
}
