@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 20px 60px 40px;

  .headerWrapper {
    background: #fff;
    box-sizing: border-box;
    z-index: 1;
  }

  .tableControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }

  .tableContainer {
    border-radius: 8px;
    overflow-x: initial;

    table {
      border-collapse: initial;
    }

    td {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
      &:nth-child(1) {
        width: 15%;
        min-width: 180px;
      }
      &:nth-child(2) {
        width: 15%;
        min-width: 160px;
      }
      &:nth-child(3) {
        width: 15%;
        min-width: 160px;
      }
      &:nth-child(4) {
        width: 10%;
        min-width: 140px;
      }
      &:nth-child(5) {
        width: 15%;
        min-width: 195px;
      }
      &:nth-child(6) {
        width: 15%;
        min-width: 150px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 170px 20px 20px;
    &.fitContainer {
      padding: 110px 20px 20px;
    }

    .headerWrapper {
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      z-index: 6;
      padding: 10px 20px 0;
    }

    .tableControls {
      flex-direction: row-reverse;
      padding: 20px 0 10px 0;
      &.fitControls {
        padding: 0 0 10px 0;
      }
    }

    .tableContainer {
      position: relative;
      left: -20px;
      width: 100vw;
      overflow-x: auto;
      td {
        &:nth-child(1) {
          min-width: 200px;
        }
        &:nth-child(2) {
          min-width: 200px;
        }
        &:nth-child(3) {
          min-width: 200px;
        }
        &:nth-child(4) {
          min-width: 150px;
        }
        &:nth-child(5) {
          min-width: 210px;
        }
        &:nth-child(6) {
          min-width: 200px;
        }
      }
    }
  }
}
