@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 30px 60px 30px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .headerBlock {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $main_color;
    padding-bottom: 10px;
    align-items: flex-end;
    margin: 0 0 40px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin: 0;
      white-space: normal;
      word-break: break-all;
    }

    button {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      cursor: pointer;
      font-family: $monserrat;
      margin: 0 0 0 25px;
      padding: 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      outline: none;
    }

    &__controls {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        color: black;
      }
    }
  }

  .tableContainer {
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 15px;

    table {
      border-collapse: initial;
    }

    td {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
        padding: 0;
      }
    }

    th {
      padding: 0 10px !important;
    }
    .checkboxHeadCell,
    .notesHeadCell,
    .dateHeadCell,
    .statusHeadCell,
    .truckHeadCell,
    .freightBillHeadCell {
      white-space: nowrap;
    }
    .loadHeadCell {
      text-align: center;
    }

    .emptyResponseTable {
      background-color: #f7f7f7;
      padding: 25px 0;
      font-size: 18px;
      text-align: center;
    }

    .sortHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;
      width: 100%;

      & > svg {
        opacity: 0.2;
        transition: all 0.15s ease-in-out;
      }

      &:hover,
      &.sorted {
        & > svg {
          opacity: 1;
        }
      }
      &.ascend {
        & > svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .checkbox {
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &__rihghtMargin {
      margin-right: 20px;
    }
  }
  .checked {
    color: $main_color !important;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }

  .loadFileSection {
    display: flex;
    justify-content: center;
  }

  .checkboxCell {
    width: 45px;
    min-width: 45px;
    padding: 8px 10px !important;
    &.highlighted {
      background-color: rgba(80, 122, 142, 0.5) !important;
    }
  }

  .drIdCell {
    width: 20%;
    min-width: 158px;
    white-space: break-spaces;
    word-break: break-word;
  }
  .locationCell {
    width: 15%;
    min-width: 137px;
    padding: 8px 10px !important;
    white-space: break-spaces;
    word-break: break-word;
  }
  .noteCell {
    width: 20%;
    min-width: 145px;
    white-space: break-spaces;
    word-break: break-word;
  }
  .dateCell {
    width: 15%;
    min-width: 150px;
  }
  .statusCell {
    width: 15%;
    min-width: 175px;
  }
  .truckCell {
    width: 15%;
    min-width: 160px;
  }
  .loadCell {
    width: 5%;
    min-width: 60px;
  }
  .freightBillCell {
    width: 5%;
    min-width: 100px;
    padding: 8px 10px !important;
  }
  .lastCell {
    width: 45px;
    min-width: 45px;
    .imageBlock {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .notesBlock,
  .datesBlock,
  .truckBlock,
  .drIdsBlock,
  .statusBlock {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 10px;
    box-sizing: border-box;
    .helpers {
      display: flex;

      button {
        margin: 4px 0 0;
        flex: 1;
        border-radius: 4px;
        padding: 0;
        min-width: 40px;

        &:first-child {
          margin-right: 5px;
        }

        svg {
          height: 15px;
          padding: 3px;
        }
      }
    }
    .alert {
      color: #fff;
      background: $alert_color;
    }
    .success {
      color: #fff;
      background: $regular_color;
    }
  }

  .drIdsBlock {
    padding: 8px 8px;
    & > a {
      margin-right: 5px;
    }
    .pencil {
      border: none;
      background: none;
      cursor: pointer;
      font-family: $monserrat;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      outline: none;
      svg {
        color: black;
        width: 20px;
        height: 20px;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }

  .notesBlock {
    max-width: 100%;
    position: relative;

    & > div {
      display: flex;

      button {
        margin: 4px 0 0;
        flex: 1;
        border-radius: 4px;
        padding: 0;

        &:first-child {
          margin-right: 5px;
        }

        svg {
          height: 15px;
          padding: 3px;
        }
      }
    }

    .textInput {
      background: #f7f7f7;

      fieldset {
        border: none;
      }
      textarea {
        font-family: $monserrat;
        font-weight: 400;
        padding: 8px;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
        color: #000;

        &::placeholder {
          opacity: 1;
          color: #000;
        }
      }
      input {
        padding: 3px 5px;
      }
    }

    & > div:first-child {
      & > div {
        width: auto !important;
        border: 1px solid #999;
        padding: 2px !important;
        font-size: 14px;
        border-radius: 8px;
      }
      & > svg {
        right: 5px;
      }
    }
  }

  .datesBlock {
    max-width: 100%;
    box-sizing: border-box;
    & > div:first-child {
      border: 1px solid #999;
    }
  }

  .statusBlock {
    max-width: 100%;
    box-sizing: border-box;
    & > div:first-child {
      width: 100%;
      & > div {
        width: 100% !important;
        font-size: 14px;
        border-radius: 8px;
        & > div {
          width: 100% !important;
          border-color: #999;
          line-height: 20px;
        }
      }
      & > svg {
        right: 5px;
      }
    }
  }

  .tableHelpers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    margin-top: 20px;
    .delete_button,
    .status_button,
    .create_bol_button {
      width: 150px;
      height: 30px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      font-family: $monserrat;
    }
    &__leftBlock {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      .statusField {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 427px;
        .status_button {
          background: $extra_color;
          color: black;
        }
      }
    }
    &__controls {
      display: flex;
      .delete_button {
        background: $main_color;
        color: white;
      }
      .create_bol_button {
        background: $main_color;
        color: white;
        width: 200px;
        margin-right: 15px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1280) {
  .headerBlock {
    button {
      label {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 60px 20px 20px;
    height: auto;

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin: 20px 0;
      white-space: normal;
      word-break: break-all;
    }

    .headerBlock {
      border-bottom: none;
      position: fixed;
      height: 60px;
      padding: 10px 20px 0;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      z-index: 10;
      margin: 0;

      button {
        background: #f6f6f6;
        border-radius: 8px;
        height: 30px;
        width: 30px;
        justify-content: center;
      }

      &__controls {
        position: fixed;
        right: 20px;
        top: 20px;

        svg {
          margin-right: 0;
        }
      }
    }

    .tableContainer {
      position: relative;
      left: -20px;
      width: 100vw;
      overflow-y: hidden;
    }

    .tableHelpers {
      flex-direction: column;
      width: 100%;
      row-gap: 10px;
      &__leftBlock {
        width: 100%;
        row-gap: 10px;
      }
      &__controls {
        display: flex;
        flex-direction: column;
        width: 100%;
        .delete_button {
          width: 100%;
          margin-bottom: 10px;
        }
        .create_bol_button {
          width: 100%;
          order: 1;
          margin-right: 0;
        }
      }
      .statusField {
        width: 100%;
        & > div {
          width: 100%;
          & > div {
            width: 100%;
          }
        }
        .status_button {
          margin-left: 20px;
          width: 30px;
          min-width: 30px;
          max-width: 30px;
        }
      }
    }
  }
}
