@import 'assets/styles/variables.scss';

.checkboxCell,
.locationCell,
.removeCell,
.quantityCell,
.itemsCell,
.sublocationCell,
.commonCell {
  &.error {
    background-color: #f7e5e5 !important;
  }
  &.warning {
    background-color: #ffefd0 !important;
  }
  .errorBlock {
    & > span {
      white-space: normal;
      font-size: 9px;
      font-family: $monserrat;
      color: #ff0000;
      display: block;
    }
    .side_space {
      padding: 0 12px 8px;
    }
  }
}

.checkboxCell {
  .checkbox {
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .checked {
    color: $main_color !important;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
}

.itemsCell {
  .rowName {
    font-weight: 400;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

.sublocationCell {
  padding: 0 !important;
  & > div {
    width: 100%;
    & > div {
      width: 155px !important;
      max-width: 155px !important;
    }
  }
}
.locationCell {
  padding: 0 !important;
  & > div {
    width: 155px !important;
    max-width: 155px !important;
  }
}
.currentLocationCell {
  padding: 0 !important;
  & > div {
    width: 260px !important;
    max-width: 260px !important;
  }
}

.removeCell {
  .removeBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.quantityCell {
  .quantityBlock {
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 100% !important;
    min-height: 20px;
    color: black;

    .textInput {
      width: 100%;
      & > div {
        width: 100%;
        border: 1px solid #999;
        padding: 2px !important;
        font-size: 14px;
        border-radius: 8px;
        height: 34.625px;
      }
      & > svg {
        right: 5px;
      }

      fieldset {
        border: none;
      }
      input,
      textarea {
        font-family: $monserrat;
        font-weight: 400;
        padding: 8px;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
        color: #000;

        &::placeholder {
          opacity: 1;
          color: #000;
        }
      }
      input {
        padding: 3px 5px;
      }
    }

    .helpers {
      display: flex;
      button {
        margin: 4px 0 0;
        flex: 1;
        border-radius: 4px;
        padding: 0;
        min-width: 20px;
        &:first-child {
          margin-right: 5px;
        }
        svg {
          height: 15px;
          padding: 3px;
        }
      }
    }
    .alert {
      color: #fff;
      background: $alert_color;
    }
    .success {
      color: #fff;
      background: $regular_color;
    }
  }
}
