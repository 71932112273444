@import 'assets/styles/variables.scss';

.wrapper {
  font-family: $monserrat;
  padding: 20px 60px 40px;

  .header {
    background: #fff;
    box-sizing: border-box;
    z-index: 1;
    &__controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 0;
    }
  }

  .table {
    border-radius: 8px;
    overflow-x: initial;
    margin-bottom: 30px;

    table {
      border-collapse: initial;
    }

    tr {
      &:hover {
        & > td {
          background-color: $hovered-row-color;
        }
      }
    }

    th {
      &:not(:nth-child(3)) {
        white-space: nowrap;
      }
      @extend %table-cell-sizes;
    }
    td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
      @extend %table-cell-sizes;
    }
  }
}

%table-cell-sizes {
  &:nth-child(1) {
    width: 5%;
    min-width: 110px;
  }
  &:nth-child(2) {
    width: 10%;
    min-width: 140px;
  }
  &:nth-child(3) {
    width: 15%;
    min-width: 240px;
  }
  &:nth-child(4) {
    width: 15%;
    min-width: 175px;
  }
  &:nth-child(5) {
    width: 15%;
    min-width: 140px;
  }
  &:nth-child(6) {
    width: 10%;
    min-width: 140px;
  }
  &:nth-child(7) {
    width: 15%;
    min-width: 140px;
  }
  &:nth-child(8) {
    width: 15%;
    min-width: 190px;
  }
}

@media screen and (max-width: $breakpoint-1450) {
  .wrapper {
    .table {
      overflow-x: auto;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    padding: 170px 20px 20px;

    .header {
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      margin: 0;
      z-index: 6;
      padding: 10px 20px 0;
      &__controls {
        flex-direction: row-reverse;
        padding: 20px 0 10px 0;
      }
    }

    .table {
      position: relative;
      left: -20px;
      width: 100vw;
      th,
      td {
        @extend %table-cell-mobile-sizes;
      }
    }
  }

  %table-cell-mobile-sizes {
    &:nth-child(1) {
      min-width: 110px;
    }
    &:nth-child(2) {
      min-width: 150px;
    }
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      min-width: 200px;
    }
  }
}
