a.link {
  text-decoration: none;
  cursor: default;
  &.underline {
    text-decoration: underline;
    cursor: pointer;
  }
  &.black {
    color: black;
  }
  &.bold {
    font-weight: bold;
  }
}
