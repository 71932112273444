@import 'assets/styles/variables.scss';

button.header_button {
  height: 30px;
}

.pageWrapper {
  font-family: $monserrat;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 60px);
  box-sizing: border-box;
  .pageContainer {
    .headerWrapper {
      background: #fff;
      box-sizing: border-box;
      z-index: 1;

      .addLooseItemsButton {
        display: flex;
        align-items: center;
        border: none;
        background: none;
        cursor: pointer;
        font-family: $monserrat;
        text-transform: uppercase;
        margin: 0;
        padding: 5px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        outline: none;
        height: 35px;
        img {
          margin-right: 10px;
          color: black;
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }

      .controls {
        display: flex;
        justify-content: space-between;
      }

      .tableControls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 0;
        &__block {
          display: flex;
          align-items: center;
          column-gap: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  button.header_button {
    width: 100%;
  }

  .pageWrapper {
    font-family: $monserrat;
    padding: 150px 20px 20px;
    min-height: 100vh;
  }
  .pageContainer {
    .headerWrapper {
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      z-index: 1;
      padding: 10px 20px 0;

      .controls {
        width: 100%;
        column-gap: 20px;
      }

      .tableControls {
        padding: 10px 0 10px 0 !important;
        flex-direction: row-reverse !important;
        align-items: center;
        column-gap: 20px;
        &__block {
          width: 100%;
        }
      }
    }
  }
}
