@import 'assets/styles/variables.scss';

.content {
  display: flex;
  flex-direction: row;

  .mainContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    position: relative;

    &__root {
      width: 100%;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .content {
    .mainContainer {
      height: auto;
    }
  }
}
